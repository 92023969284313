import JustValidate from 'just-validate';
import Inputmask from "../../../node_modules/inputmask/dist/inputmask.es6.js";
import { disableScroll } from '../functions/disable-scroll.js';

export const validateForms = (selector, rules, checkboxes = [], afterSend) => {
  const form = document?.querySelector(selector);

  form._submit_ = function () {
    form._validation_.revalidate().then(async isValid => {

      if (!isValid) return grecaptcha.reset();

      let formData = new FormData(form);

      let xhr = new XMLHttpRequest();

      let formSendPopup = document.querySelector('.formsend-popup');
      let formPopup = document.querySelector('.form-popup')
      let formMailPopup = document.querySelector('.formmail-popup')
      disableScroll();

      formMailPopup.classList.remove('active');
      formSendPopup.classList.add('active');
      formPopup.classList.remove('active');

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            if (afterSend) {

              afterSend();

              // Метрика на все формы
              ym(98541057,'reachGoal','obschaya');

              if(form.classList.contains('credit-form')) {
                // Метрика на форму решения
                ym(98541057,'reachGoal','reshenie');
              };

              if(form.classList.contains('popup-form')) {
                // Метрика на все формы заявок
                ym(98541057,'reachGoal','zayavka');
              };

            }
          }
        }
      }

      xhr.open('POST', 'mail.php', true);
      xhr.send(formData);

      form.reset();
      grecaptcha.reset();

    });

  }

  const telSelector = form?.querySelector('input[type="tel"]');

  if (!form) {
    console.error('Нет такого селектора!');
    return false;
  }

  if (!rules) {
    console.error('Вы не передали правила валидации!');
    return false;
  }

  if (telSelector) {
    const inputMask = new Inputmask('+7 (999) 999-99-99');
    inputMask.mask(telSelector);

    for (let item of rules) {
      if (item.tel) {
        item.rules.push({
          rule: 'function',
          validator: function() {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: item.telError
        });
      }
    }
  }



  const validation = new JustValidate(selector);
  form._validation_ = validation;

  for (let item of rules) {
    validation
      .addField(item.ruleSelector, item.rules);
  }

  if (checkboxes.length) {
    for (let item of checkboxes) {
      validation
        .addRequiredGroup(
          `${item.selector}`,
          `${item.errorMessage}`
        )
    }
  }

};
